import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class NotasfiscaisService {
   url = "https://api.dashboard.opservidor.com/api/notasfiscais";
  //url = "http://localhost:5415/api/notasfiscais";
  
  constructor(public http: HttpClient) { 
    
  }

  getNotas(){
    return this.http.get(`${this.url}`);
  }
  postNotas(cod_nfe, opcao, valor,sistema){
    return this.http.get(this.url+"?cod_nfe="+cod_nfe+"&opcao="+opcao+"&valor="+valor+"&sistema="+sistema);
  }
}
