import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactCreateComponent } from './contact-create/contact-create.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { HeaderComponent } from './header/header.component';
import { DashComponent } from './dash/dash.component';
import { HttpClientModule } from '@angular/common/http';
import { ChamadosComponent } from './chamados/chamados.component';
import { MonitoramentoComponent } from './monitoramento/monitoramento.component';
import { Monitoramento2Component } from './monitoramento2/monitoramento2.component';
import { NotasfiscaisComponent } from './notasfiscais/notasfiscais.component';
import { SairComponent } from './sair/sair.component';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// const config: SocketIoConfig = { url: 'http://localhost:3333', options: {} };
const config: SocketIoConfig = { 
  url: 'https://monit.dashboard.opservidor.com', 
  // options: {
  //   transports: ['websocket'],
  //   upgrade: true
  // } 
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactCreateComponent,
    ContactListComponent,
    HeaderComponent,
    DashComponent,
    ChamadosComponent,
    MonitoramentoComponent,
    Monitoramento2Component,
    NotasfiscaisComponent,
    SairComponent,
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserModule, SocketIoModule.forRoot(config)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
