import { Component, OnInit } from '@angular/core';
import { MonitorService } from '../monitor.service';
import { Socket } from 'ngx-socket-io';
import { NotasfiscaisService } from '../notasfiscais.service';






@Component({
  selector: 'app-monitoramento2',
  templateUrl: './monitoramento2.component.html',
  styleUrls: ['./monitoramento2.component.css']
})
export class Monitoramento2Component implements OnInit {

  lojas: Array<any> = ['15', '22', '21', '11', '13', '7', '4', '3', '18', '14', '20'];
  status: Array<any> = [];
  statusLoja: Array<any> = [];
  statusClinica: Array<any> = [];
  nfeStatusGeral: {
    otica: number,
    social: number,
  } = { otica: 0, social: 0 };

  // statusAtu: Array<any> = [];

  constructor(public monitor: MonitorService, private socket: Socket, private nfService: NotasfiscaisService) {
    console.log('monitor',123)
    try {
      

    nfService.getNotas().subscribe((data: Array<any>) => {
      this.nfeStatusGeral.otica = data.filter(x =>
        x.sistema == 'OTICA' &&
        x.Log &&
        x.Log.length > 0 &&
        x.Log[x.Log.length - 1].msg
          .toLowerCase()
          .indexOf('erro') >= 0).length;
      this.nfeStatusGeral.social = data.filter(x =>
        x.sistema !== 'OTICA' &&
        x.Log &&
        x.Log.length > 0 &&
        x.Log[x.Log.length - 1].msg
          .toLowerCase()
          .indexOf('erro') >= 0).length;
    });
    } catch (error) {
        console.log('error', error)
  }

    this.status = this.lojas.map((loja) => {
      return { cod_loja: loja }
    })
    console.log('status', this.status)
    // this.statusLoja = this.lojas.map((loja) => {
    //   return { cod_loja: loja }
    // })
    // this.statusClinica = this.lojas.map((loja) => {
    //   return { cod_loja: loja }
    // })
    socket.on('status_geral', data => {
      // console.log('daa', data)
      if (this.status == null) {
        this.status = data.map(it => {
          let pc = (it.qtd * 100 / it.total).toFixed(0);
          return { pc, wid: pc + "%", ...it };
        }).sort((a, b) => { return a.nome > b.nome ? 1 : -1 });
      }
      data.map(it => {
        let pc = (it.qtd * 100 / it.total).toFixed(0);
        let ob = { ...it, pc, wid: pc + "%", ...it };
        let st = this.status.find(x => x.cod_loja == it.cod_loja && x.isLoja == it.isLoja)
        let add = false;
        if (!st) {
          st = {};
          add = true;
        }
        for (let k of Object.keys(ob)) {
          st[k] = ob[k]
        }
        if (add) {
          this.status.push(st);
        }
      });

      this.statusLoja = this.status.filter(x => x.isLoja == true).sort((a, b) => { return a.cod_loja == 15 ? -1 : 1 });
      this.statusClinica = this.status.filter(x => x.isClinica == true).sort((a, b) => { return a.cod_loja == 15 ? -1 : 1 });
      // console.log('status loja', this.statusLoja)
      // console.log('status cli', this.statusClinica)
    })

  }


  ngOnInit() {
  }

}
