import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { usuarioLogado } from './app.component';

@Injectable({
  providedIn: 'root'
})
export class ChamadosService {
  url = "https://api.dashboard.opservidor.com/api/chamados";
  // url = "http://localhost:5415/api/chamados";
  constructor(public http: HttpClient) {

  }

  async transfereChamado(cod_chamado, novoSetor) {
    console.log(cod_chamado, novoSetor)
    return await this.http.post(this.url + '/transferir', { cod_chamado, novoSetor, cod_usuario: usuarioLogado.cod_usuario, usuario: usuarioLogado.nome })
  }

  getChamados(cod_chamado, buscar, hoje = false) {
    if (buscar == null)
      buscar = false;

    let urr = this.url + (cod_chamado != '' ? "?cod_chamado=" + cod_chamado : "") + (buscar ? (cod_chamado != '' ? "&" : "?") + "buscar=true" : "");

    if (hoje) {
      urr = this.url + "?cod_chamado=&buscar=false&hoje=true";
    }

    return this.http.get(urr);
  }

  registraStatus(CodChamado, Status, Obs, Imagem) {
    // console.log('-asda-', { cod_chamado: CodChamado, status: Status, obs: Obs, cod_usuario: usuarioLogado.cod_usuario, usuario: usuarioLogado.nome, imagem: Imagem })
    return this.http.post(this.url, { cod_chamado: CodChamado, status: Status, obs: Obs, cod_usuario: usuarioLogado.cod_usuario, usuario: usuarioLogado.nome, imagem: Imagem });
  }

  storeChamado(obj) {
    return this.http.post(this.url + "tore/store", obj);

  }
  upload(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('files', fileToUpload, fileToUpload.name);

    return this.http
      .post(this.url.replace("api/chamados", "") + "fileUpload", formData);

  }
}
