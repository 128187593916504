import { PortcheckService } from './../portcheck.service';
import { MonitoramentoService } from './../monitoramento.service';
import { Component, OnInit } from '@angular/core';
import { usuarioLogado } from '../app.component';


@Component({
  selector: 'app-monitoramento',
  templateUrl: './monitoramento.component.html',
  styleUrls: ['./monitoramento.component.css']
})
export class MonitoramentoComponent implements OnInit {


  constructor(public mointoramentSrv: MonitoramentoService, public portChkSrv: PortcheckService) {
    /*this.timerMonitor = setInterval(()=>{
      this.buscarMonitoramento();
    }, 2500);

    /*setInterval(()=>{
      this.checkDVR();
    },2000);*/

    // this.checkDVR();


    this.buscarMonitoramento();



  }

  timerMonitor;

  monitoramentoLOJAS;
  monitoramentoCLINICAS;
  executando = false;


  exec2 = false; execTOTEM = false;

  buscarMonitoramento() {
    if (this.executando || !usuarioLogado.master) {
      return;
    }

    this.executando = true;
    this.mointoramentSrv.getMonitoramento().subscribe((data: Array<any>) => {
      if (!this.monitoramentoLOJAS) {
        this.monitoramentoLOJAS = data.filter(x => x.grupo == 'LOJAS');
      } else {
        for (let p of data.filter(x => x.grupo == 'LOJAS')) {
          this.monitoramentoLOJAS.find(x => x.local == p.local).status = p.status;
          this.monitoramentoLOJAS.find(x => x.local == p.local).UltimaAtualizacao = p.UltimaAtualizacao;
          this.monitoramentoLOJAS.find(x => x.local == p.local).situacao = p.situacao;
          this.monitoramentoLOJAS.find(x => x.local == p.local).buscar = p.buscar;
          this.monitoramentoLOJAS.find(x => x.local == p.local).enviar = p.enviar;
        }
      }
      this.monitoramentoCLINICAS = data.filter(x => x.grupo == 'CLINICAS');
      this.executando = false;
      this.delay(500).then(() => {
        // this.checkDVRS();
        this.buscarMonitoramento();
        // this.checkTotens();
      });
    });
    // tslint:disable-next-line: no-trailing-whitespace

  }

  async checkDVRS() {

    console.log("checandodvr");
    if (this.monitoramentoLOJAS != null && this.monitoramentoLOJAS.length > 0) {
      await this.checkDVR(this.monitoramentoLOJAS);
    }

  }

  async checkTotens() {

    if (this.monitoramentoLOJAS != null && this.monitoramentoLOJAS.length > 0) {
      await this.checkTotem(this.monitoramentoLOJAS);
    }

  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then();
  }
  async checkDVR(dt) {

    for (let m of dt) {
      // m.dvr = "OK"
      await this.portChkSrv.check(m.ip, 34567, 1).subscribe((data) => {
        if (data == null) {
          m.dvr = "FALHA";
        }
        else {
          m.dvr = data == 1 ? 'OK' : "FALHA";
        }
      }, (error) => {
        m.dvr = "FALHA";
      });
    }

  }

  async checkTotem(dt) {

    for (let m of dt) {
      await this.portChkSrv.check(m.ip, 6000, 10, 1).subscribe((data) => {
        if (data == null) {
          m.totem = "FALHA";
        }
        else {
          m.totem = data.toString() == '1' ? 'OK' : "FALHA";
        }
        console.log(m.local, m.ip, data);
      }, (error) => {
        m.totem = "FALHA";
      });
    }

  }



  ngOnInit() {
  }

}
