import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { usuarioLogado } from '../app.component';

@Component({
  selector: 'app-sair',
  templateUrl: './sair.component.html',
  styleUrls: ['./sair.component.css']
})
export class SairComponent implements OnInit {

  constructor(router: Router) {
    usuarioLogado.cod_usuario = -1;
    usuarioLogado.nome = "";
    localStorage.clear();
    router.navigate(["home"]);
  }

  ngOnInit() {
  }

}
