import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class MonitoramentoService {

  constructor(public http: HttpClient) { }

  url = "https://api.dashboard.opservidor.com/api/monitoramento";
    //url = "http://localhost:5415/api/monitoramento";

  getMonitoramento(){
    return this
    .http
    .get(`${this.url}`);
  }
}
