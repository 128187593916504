import { usuarioLogado, Usuario } from './app.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url = "https://api.dashboard.opservidor.com/api/login";
  //url = "http://localhost:5415/api/login";
  
  _routerSrv: Router;
  constructor(public httpSrc:HttpClient, private router: Router) { 
    this._routerSrv = router;
  }

  login(usu,sen){
    return this.httpSrc.post(this.url, {usuario:usu, senha:sen});
  }

  verificarLogado(){
    // let usuarioLogado = null;
    let user = localStorage.getItem('user');
    if(!user){
      this._routerSrv.navigate(['home']);
    }
    const us = JSON.parse(user)[0];
    console.log('VERIFICA USU', user, us);
    if (user && user != '' && us) {
      usuarioLogado.cod_usuario = us.cod_usuario;
      usuarioLogado.nome = us.nome;
      usuarioLogado.master = us.master;
      if (usuarioLogado.cod_usuario == 341 || usuarioLogado.cod_usuario == 1000026) {
        usuarioLogado.master = false;
      }
      usuarioLogado.adm_chamado = us.adm_chamado;
    }

    return usuarioLogado;
  }
}
