import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PortcheckService {

  url = "https://api.dashboard.opservidor.com/api/checkport/";
  constructor(public http: HttpClient) {

  }
  asyncResult;
  check(ip, port, timeout,type=0){
    return this.http.get(this.url+"/?ip=" + ip + "&port=" + port + "&timeout=" + timeout + "&type=" + type);
  }
  async getAsyncData(ip, port, timeout) {
    return await this.http.get(this.url+"/?ip=" + ip + "&port=" + port + "&timeout=" + timeout).toPromise();
  }
}
