import { ChamadosService } from './../chamados.service';
import { MonitoramentoService } from './../monitoramento.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { NotasfiscaisService } from '../notasfiscais.service';
import { NgSwitchCase } from '@angular/common';
import { usuarioLogado } from '../app.component';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';



@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})


export class DashComponent implements OnInit {

  usuario = usuarioLogado;

  isResumed = false;


  constructor(public router: Router, public loginsrc: LoginService,) {
    this.loginsrc.verificarLogado();
    // if (usuarioLogado.cod_usuario <= 0) {
    //   router.navigate(['home']);
    // }

    console.log("monitor", router.url)

    this.isResumed = router.url.indexOf('monitor') >= 0


    //this.buscarMonitoramento();
  }





  ngOnInit() {
    // console.log("=== href ==>", window.location.href)
  }

}
