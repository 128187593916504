import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  constructor(private socket: Socket) { }

  status_geral(data: any) {
    console.log('dados', data);
  }

}
